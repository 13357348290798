import React from 'react';
import Navbar from './NavBar';

const PageSkeleton = () => {
  return (
    <>
      <div className='page-skeleton-loader property-page-header'>
        <Navbar />
        <div className='secondary-page-header'>
          <div className='loading-comp-bg-img loading-comp bg-image'>
            {/* <img src='/image-2.png' width='100%' height='100%' /> */}
          </div>
          <div className='overlay-text'>
            <div className='mb-5 d-flex align-items-center justify-content-center'>
              <div className='loading-comp-txt page-skeleton-txt'></div>
            </div>
            <div className='w-100'>
              <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
              <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
              <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
            </div>
          </div>
        </div>
        <div className='loading-comp-txt page-skeleton-txt'></div>
        <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
        <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
        <div className='mx-auto w-70 mb-3 loading-comp-txt loading-comp-desc'></div>
      </div>
    </>  
  )
}
export default PageSkeleton;