// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
// import Home from './Pages/Home';
import Home2 from './Pages/Home-2';
import Buy from './Pages/Buy';
import Sell from './Pages/Sell';
import PropertyDetails from './Pages/PropertyDetails';
import ContactUs from './Pages/ContactUs';
import AboutUs from './Pages/AboutUs';
import Blogs from './Pages/Blogs';
import ScrollToTop from './components/ScrollToUp';

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* <Route path="/home2" element={<Home />} /> */}
          <Route path="/" element={<Home2 />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/properties/:property_id" element={<PropertyDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blogs" element={<Blogs />} />
          {/* <Route path="/profile/:username" component={ProfileDetailPage} />
          <Route path="/feed" component={FeedPage} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
