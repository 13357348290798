import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Popover, Whisper } from 'rsuite';
import { FaSearch } from "react-icons/fa";
import 'rsuite/Popover/styles/index.css';
import 'react-phone-input-2/lib/style.css';
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { GiModernCity } from "react-icons/gi";
import Select from 'react-select';
import {coutriesList} from '../utils/constants';

const SearchForm = ({ countryCode = '', setProperties, pageNumber, setTotalResults, setPageCount, coutriesList, setLoadingState }) => {

  const [displayAddress, setDisplayAddress] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [countryName, setCountryName] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [bedrooms, setBedroomsCount] = useState('');
  const [baths, setBathsCount] = useState('');
  const [moreFilterApplied, setMoreFilterApplied] = useState(false);
  const [areaAutocompleteList, setAreaAutocompleteList] = useState([]);
  const [isAreaLoading, setIsAreaLoading] = useState(false);
  const triggerRef = React.useRef();
  const propertyTypeRef = React.useRef();

  const initialize = () => {
    handleSearch();
    // getAreaAutocompleteList(selectedCountryCode);
    // Add any other initialization logic here
  };

  // useEffect hook to call the initialize function on component mount
  useEffect(() => {
    initialize();
  }, [pageNumber]);

  useEffect(() => {
    // getAreaAutocompleteList(selectedCountryCode);
  }, [selectedCountryCode]);

  useEffect(() => {
    if (propertyType || bedrooms || baths) {
      setMoreFilterApplied(true);
    } else {
      setMoreFilterApplied(false);
    }
  }, [propertyType, bedrooms, baths]);


  const handleSearch = async () => {
    try {
      setLoadingState(true);
      let url = 'https://cfsi-strapi.onrender.com/api/properties?'
      // let url = 'http://localhost:1337/api/properties?'
      if (displayAddress) {
        let { value } = displayAddress
        url = url + `filters[display_address][$containsi]=${value}&`;
      }
      if (bedrooms) {
        url = url + `filters[bedrooms][$eq]=${bedrooms}&`;
      }
      if (baths) {
        url = url + `filters[bathrooms][$eq]=${baths}&`;
      }
      if (selectedCountryCode) {
        let countryName=coutriesList.find((country) => country.countryCode === selectedCountryCode)?.countyName || '';
        setCountryName(countryName);
        if (countryName) {
          url = url + `filters[country][$eq]=${countryName}&`;
        }
      }
      if (selectedPropertyType) {
        url = url + `filters[property_type][$eq]=${selectedPropertyType}&`;
      }
      if (pageNumber) {
        url = url + `pagination[pageSize]=12&pagination[page]=${pageNumber}&`
      }
      url = url + 'populate=*';
      const response = await axios.get(url);
      let properties = response?.data?.data || [];
      let totalResults = response?.data?.meta?.pagination?.total;
      let pageCount = response?.data?.meta?.pagination?.pageCount;
      setProperties(properties);
      setTotalResults(totalResults);
      setPageCount(pageCount);
    } catch (error) {
      console.error('Error making network request', error);
    } finally {
      setLoadingState(false);
    }
  };

  async function getAreaAutocompleteList(countryCode) {
    setIsAreaLoading(true);
    let country = ''
    if (countryCode) {
      country = coutriesList.find((ctry) => ctry.countryCode === countryCode)?.countyName || '';
    }
    const response = await axios.get(`https://cfsi-strapi.onrender.com/api/autocomplete/propertyAddress?countryCode=${country}`);
    setAreaAutocompleteList(response.data || []);
    setIsAreaLoading(false);
  }

  function selectCountryFilter(coutryCodeSelected) {
    setSelectedCountryCode(coutryCodeSelected);
    let countryName=coutriesList.find((country) => country.countryCode === coutryCodeSelected)?.countyName || '';
    setCountryName(countryName);
    setDisplayAddress('');
    triggerRef.current.close();
  }
  function selectPropertyType(propertyType) {
    setSelectedPropertyType(propertyType);
    propertyTypeRef.current.close();
  }


  return (
    <div className='w-80 my-3'>

      <form onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
        <div className='d-flex justify-content-center buy-search-bar'>
          <label className='flex-grow-1 px-2 area-search-autocomplete'>
            <Select value={displayAddress} onChange={(newValue) => {setDisplayAddress(newValue)}} options={areaAutocompleteList} placeholder="Select any area" className='mt-1' isLoading={isAreaLoading} />
          </label>
          <div className='vertical-seperator'></div>
          <Whisper
            placement="bottom"
            trigger="click"
            ref={triggerRef}
            speaker={
              <Popover arrow={false} className='p-0'>
                <ul className='property-type-popover react-tel-input'>
                  {coutriesList.map((country, index) => (
                    // <Property key={index} property={property} />
                    <li onClick={() => selectCountryFilter(country.countryCode)} ><div className={`${country.flag} flag `}></div>{country.countyName}</li>
                  ))}
                  <hr className='m-0 p-0'/>
                  <li onClick={() => selectCountryFilter('')} ><div className='flag btn-link'></div>Clear Filter</li>
                  {/* <li onClick={() => selectCountryFilter('ae')} ><div className='flag ae'></div>UAE</li>
                  <li onClick={() => selectCountryFilter('sa')} ><div className='flag sa'></div>Saudi Arabia</li>
                  <li onClick={() => selectCountryFilter('gb')} ><div className='flag gb'></div>UK</li>
                  <li onClick={() => selectCountryFilter('es')} ><div className='flag es'></div>Spain</li>
                  <li onClick={() => selectCountryFilter('fr')} ><div className='flag fr'></div>France</li>
                  <li onClick={() => selectCountryFilter('kw')} ><div className='flag kw'></div>Kuwait</li>
                  <li onClick={() => selectCountryFilter('ir')} ><div className='flag ir'></div>Iran</li> */}
                </ul>
              </Popover>
            }
          >
            <button className='search-bar-btn flex-grow-1 px-2 d-flex justify-content-center align-items-center country-search-bar' type='button'>
              {selectedCountryCode ? <div className="property-type-popover react-tel-input d-flex justify-content-center align-items-center"><div className={`flag me-4 ${selectedCountryCode}`}></div><div>{countryName}</div></div> : <div><LiaGlobeAmericasSolid className="coutry-globe-icon mx-2" />Country</div>}
            </button>
          </Whisper>
          <div className='vertical-seperator'></div>
          <Whisper
            placement="bottom"
            trigger="click"
            ref={propertyTypeRef}
            speaker={
              <Popover arrow={false} className='p-0'>
                <ul className='property-type-popover react-tel-input'>
                  <li onClick={() => selectPropertyType('Villa / Townhouse')} ><div className=''></div>Villa / Townhouse</li>
                  <li onClick={() => selectPropertyType('Appartment')} ><div className=''></div>Appartment</li>
                  <hr className='m-0 p-0'/>
                  <li onClick={() => selectPropertyType('')} ><div className='flag btn-link'></div>Clear Filter</li>
                </ul>
              </Popover>
            }
          >
            <button className='search-bar-btn flex-grow-1 px-2 d-flex justify-content-center align-items-center country-search-bar' type='button'>
              {selectedPropertyType ? <div className="property-type-popover react-tel-input d-flex justify-content-center align-items-center"><div className={`flag me-4`}></div><div>{selectedPropertyType}</div></div> : <div><GiModernCity className="coutry-globe-icon mx-2" />Property Type</div>}
            </button>
          </Whisper>
          {/* <Whisper
            placement="bottom"
            trigger="click"
            speaker={<Popover arrow={false}>
              <div className='mb-3'>
                <label className='d-flex justify-content-between align-items-center'>
                  <div style={{width:"100px"}}>Property Type</div>
                  <select
                    className='ms-3'
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                    placeholder='Min Price'
                    style={{width:"150px"}}
                  >
                    <option value=""  selected>-</option>
                    <option value="house">House</option>
                    <option value="apartment">Apartment</option>
                    <option value="condo">Condo</option>
                    <option value="townhouse">Townhouse</option>
                    <option value="land">Land</option>
                  </select>
                </label>
              </div>
              <div className='mb-3'>
                <label className='d-flex justify-content-between align-items-center'>
                  <div style={{width:"100px"}}>Bedrooms</div>
                  <select
                    value={bedrooms}
                    onChange={(e) => setBedroomsCount(e.target.value)}
                    placeholder='Bedrooms'
                    style={{width:"150px"}}
                  >
                    <option value=""  selected>-</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5+</option>
                  </select>
                </label>
              </div>
              <div>
                <label className='d-flex justify-content-between align-items-center'>
                  <div style={{width:"100px"}}>Bathrooms</div>
                  <select
                    value={baths}
                    onChange={(e) => setBathsCount(e.target.value)}
                    placeholder='Baths'
                    style={{width:"150px"}}
                  >
                    <option value=""  selected>-</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5+">5</option>
                  </select>
                </label>
              </div>
            </Popover>}
          >
            <button className='search-bar-btn flex-grow-1 px-2' type='button'>
              More Filters {moreFilterApplied ? <span className='ms-2 filter-applied'></span> : ''}
            </button>
          </Whisper> */}
          
          <button className='buy-search-btn btn btn-primary my-2' type="submit"><FaSearch /></button>
        </div>  
      </form>
    </div> 
  );
};

export default SearchForm;