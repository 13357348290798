import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Property = ({ property: {attributes} }) => {
  const detailsStyle = {
    padding: "10px 0px",
    justifyContent: "space-between"
  }

  // function currencyFormat(num, currencyCode) {
  //   return currencyCode + ' ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // }

  return (
    <Link className="text-decoration-none" to={`/properties/${attributes.property_id}`}>
      <div className='position-relative property-container'>
        <div className="d-flex flex-column">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100 property-img" height="280px" src={attributes.primary_image?.data?.attributes?.url} alt="First slide" />
            </Carousel.Item>
            {/* {attributes.images_list?.data?.map(img => (
              <Carousel.Item style={imgStyle}>
                <img className="d-block w-100" style={imgStyle} height="200px" src={img?.attributes?.url} alt="Second slide" />
              </Carousel.Item>
            ))} */}
          </Carousel>
          <div className='d-flex flex-column' style={detailsStyle}>
            <div className='mb-1'>
              <li key={attributes.property_id}>
                <Link className="text-dark-blue mb-0 fw-bolder text-decoration-none" to={`/properties/${attributes.property_id}`}><h5 style={{fontSize: '18px', fontWeight: 'bold', marginTop: '10px'}}>{attributes.title}</h5></Link>
              </li>
              {/* <h4 className='text-dark-blue mb-0 fw-bolder'>{attributes.title}</h4> */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Property;
