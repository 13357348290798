import React, { useState, useRef, useEffect } from "react";
import { NavLink } from 'react-router-dom';
// import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../../node_modules/bootstrap/dist/js/bootstrap.js';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// function BasicExample() {
//   return (
//     <Navbar expand="lg" className="bg-body-tertiary">
//       <Container>
//         <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#home">Home</Nav.Link>
//             <Nav.Link href="#link">Link</Nav.Link>
//             <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default BasicExample;


const NavbarComponent = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const productHeaderTopRef = useRef(null);
  const productHeaderInnerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (productHeaderTopRef.current && productHeaderInnerRef.current) {
      if (isScrolled) {
        productHeaderTopRef.current.classList.remove('slide-nav-bar');
        productHeaderInnerRef.current.style.overflow = 'visible';
      } else {
        productHeaderTopRef.current.classList.add('slide-nav-bar');
        productHeaderInnerRef.current.style.overflow = 'hidden';
      }
    }
  }, [isScrolled]);

  return (
    <>
      <div className='nav-bar-bg position-absolute w-100 px-3'>
        {/* <nav className="main-nav navbar navbar-expand-lg navbar-light bg-light">
          <img src="/cfsi-logo-white.png" alt="Capital FinServ International" style={{maxWidth: '250px'}} />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className='navbar-nav ms-auto'>
                <li className='nav-item px-3 active'><NavLink className="nav-link" to="/" activeClassName="active">Home</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/buy" activeClassName="active" >Buy</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/sell" activeClassName="active" >Sell</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/about-us" activeClassName="active" >About Us</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/contact-us" activeClassName="active" >Contact Us</NavLink></li>
              </ul>
          </div>
        </nav> */}
        <Navbar expand="lg" className="main-nav">
          <Container>
            <Navbar.Brand href="/"><img src="/cfsi-logo-white.png" alt="Capital FinServ International" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="flex-grow-0" id="basic-navbar-nav">
              <Nav className="me-auto">
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/">Home</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/buy" >Buy</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/sell" >Sell</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/about-us" >About</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/contact-us">Contact Us</Nav.Link></li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div ref={productHeaderTopRef} className='position-fixed w-100 fixed-nav slide-nav-bar px-5'>
        {/* <nav ref={productHeaderInnerRef} className="container navbar navbar-expand-lg" style={{overflow: 'hidden'}}>
          <img src="/cfsi-logo-white.png" alt="Capital FinServ International" style={{maxWidth: '250px'}} />
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className='navbar-nav ms-auto'>
                <li className='nav-item px-3 active'><NavLink className="nav-link" to="/" activeClassName="active">Home</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/buy" activeClassName="active" >Buy</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/sell" activeClassName="active" >Sell</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/about-us" activeClassName="active" >About Us</NavLink></li>
                <li className='nav-item px-3'><NavLink className="nav-link" to="/contact-us" activeClassName="active" >Contact Us</NavLink></li>
              </ul>
          </div>
        </nav> */}
        <Navbar ref={productHeaderInnerRef} expand="lg" className="main-nav">
          <Container>
            <Navbar.Brand href="/"><img src="/cfsi-logo-white.png" alt="Capital FinServ International" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="flex-grow-0" id="basic-navbar-nav">
              <Nav className="me-auto">
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/">Home</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/buy" >Buy</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/sell" >Sell</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/about-us" >About</Nav.Link></li>
                <li className='nav-item px-3 active'><Nav.Link className="nav-link" as={NavLink} to="/contact-us">Contact Us</Nav.Link></li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
      <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
      <NavDropdown.Item href="#action/3.2">
        Another action
      </NavDropdown.Item>
      <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item href="#action/3.4">
        Separated link
      </NavDropdown.Item>
    </NavDropdown> */}
    </>
    
  )
};
export default NavbarComponent;