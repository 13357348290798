import React from 'react';

const LoadingComponent = () => {
  const detailsStyle = {
    padding: "10px 0px",
    justifyContent: "space-between"
  }
  return (
    <div className="text-decoration-none">
      <div className='position-relative property-container'>
        <div className="d-flex flex-column">
          <div className="loading-comp" />
          <div className='d-flex flex-column' style={detailsStyle}>
            <div className='mb-1'>
              <li>
                <div className="loading-comp-txt"></div>
              </li>
              {/* <h4 className='text-dark-blue mb-0 fw-bolder'>{attributes.title}</h4> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingComponent;