import React, { useState }  from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import ContactUsForm from '../components/ContactUsForm';
import { FaLinkedinIn } from "react-icons/fa6";
import SecondaryPageHeader from '../components/SecondaryPageHeader';

export const AboutUs = () => {

    const [showContactForm, setShowContactForm] = useState(false);
  
    function openContactUs() {
      setShowContactForm(true);
    }
  
    return (
      <div>
        <div className='header-section'>
          <Navbar />
          <SecondaryPageHeader pageTitle="About Us" pageDesc="Home / About Us" imgURL="/secondary-page-bg.png" />
        </div>
        <div className='container mb-5'>
          {/* <nav className='mt-4' aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><Link to="/Home"> Home</Link></li>
              <li class="breadcrumb-item active" aria-current="page"><a className='breadcrumb-item active text-decoration-none' href="#">Contact Us</a></li>
            </ol>
          </nav> */}
          <div className='d-flex mx-5 mt-5'>
            <div className=''>
              <h1 className='primary-text letter-spacing-15 mb-4'>Where it all started!</h1>
              <p className='md-font mb-4 letter-spacing-1 text-muted'>Looking for expert advice or further support?</p>
              <p className='md-font letter-spacing-1 text-muted'>CFSI was founded in year. In an industry known for money-based wins and pushy comms, they strived to build something different – a collaborative team environment with a strong focus on client satisfaction and long term relationships.Fast forward to 10 years later, that client focused mindset has paid off. We've grown into a trusted and professional team of 360+, including Property Consultants, Marketing Specialists, Administrators, Customer Service Experts, and more.</p>
            </div>
          </div> 
          <div className='d-flex mt-5 flex-column'>
            <h3 className='primary-text my-3'>OUR TEAM</h3>
            <div className='contact-us-card w-100 p-5 rounded mb-5'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column me-5'>
                  {/* <h2 className='letter-spacing-1 line-height-50 m-0'>Got feedback, complaints or questions? <br />Speak to a Customer Service specialist and <br/>get answers</h2> */}
                  {/* <p className='text-muted'>Get personalised assistance from our dedicated team of experts.</p> */}
                  <img decoding="async" width="150" height="150" style={{borderRadius: "50%"}} src="https://cfsi.ae/wp-content/uploads/2022/12/6Z8A1870__1_-removebg-preview-150x150.jpg" alt="" title="" srcset="https://cfsi.ae/wp-content/uploads/2022/12/6Z8A1870__1_-removebg-preview-150x150.jpg 150w, https://cfsi.ae/wp-content/uploads/2022/12/6Z8A1870__1_-removebg-preview-300x300.jpg 300w, https://cfsi.ae/wp-content/uploads/2022/12/6Z8A1870__1_-removebg-preview.jpg 312w" sizes="(max-width: 150px) 100vw, 150px"></img>
                </div>
                <div className='text-start'>
                  <h5 className='primary-text'>Madhusudan Narasimhaiah</h5>
                  <span className='text-muted'>CEO</span>
                  <p>Madhusudan Narasimhaiah, is one of the most trusted Independent Financial Advisor in the region with over a decade of experience in financial planning & wealth management solutions. Having qualification from Chartered Insurance Institute of London (CII, UK) & Association of Mutual Funds in India (AMFI), Madhusudan has built a high level of network globally with various financial professionals & vast satisfied clientele across the regions. Madhusudan has committed his career to focusing on a unique systematic, disciplined approach with immense knowledge in the industry such as Insurance, Investments & structured products, has provided his clients an opportunity to travel their life’s journey in a dignified manner with personal service throughout various stages of their life.</p>
                  <div class="member-social mt-4">
                    <span class="woodmart-social-icons icons-design-bordered icons-size-default social-form-circle p-3">
                      <a class="woodmart-social-icon social-linkedin" href="https://www.linkedin.com/in/madhusudan-n-6532491b">
                        <FaLinkedinIn />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact-us-card w-100 p-5 rounded'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column me-5'>
                  {/* <h2 className='letter-spacing-1 line-height-50 m-0'>Got feedback, complaints or questions? <br />Speak to a Customer Service specialist and <br/>get answers</h2> */}
                  {/* <p className='text-muted'>Get personalised assistance from our dedicated team of experts.</p> */}
                  <img decoding="async" width="150" height="150" style={{borderRadius: "50%"}} src="https://cfsi.ae/wp-content/uploads/2022/12/8961c989-d481-4b19-842d-49da69c7fede-removebg-preview-150x150.jpg" alt="" title="" srcset="https://cfsi.ae/wp-content/uploads/2022/12/8961c989-d481-4b19-842d-49da69c7fede-removebg-preview-150x150.jpg 150w, https://cfsi.ae/wp-content/uploads/2022/12/8961c989-d481-4b19-842d-49da69c7fede-removebg-preview-300x300.jpg 300w, https://cfsi.ae/wp-content/uploads/2022/12/8961c989-d481-4b19-842d-49da69c7fede-removebg-preview.jpg 312w" sizes="(max-width: 150px) 100vw, 150px"></img>
                </div>
                <div className='text-start'>
                  <h5 className='primary-text'>Parul Gupta</h5>
                  <span className='text-muted'>Managing Director</span>
                  <p>Parul Gupta with over 20 years of experience in the financial & insurance markets. Parul has been the primary advisor on large client portfolios and has been responsible for suitable development and implementation of customised investment advice and portfolio management for clients. Prior to setting up CFSI, Parul had spent most of his career with various banks in the UAE region with specific exposure to Equities, Fixed Income, and structure products. Parul is a Bachelor of Science and has also completed his MBA in Marketing from India. As the Managing Director of Capital Finserv International Parul has successfully built up a sound team of highly experienced financial professionals, providing unbiased investment advice to the wealthy.</p>
                  <div class="member-social mt-4">
                    <span class="woodmart-social-icons icons-design-bordered icons-size-default social-form-circle p-3">
                      <a class="woodmart-social-icon social-linkedin" href="#">
                        <FaLinkedinIn />
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex mx-5 mt-5'>
            <div className=''>
              <h1 className='primary-text letter-spacing-15 mb-4'>We are here to help you!</h1>
              <p className='md-font mb-4 letter-spacing-1 text-muted'>Looking for expert advice or further support?</p>
              <p className='md-font letter-spacing-1 text-muted'>Covering Dubai's key communities, our property experts offer a wide range of services including Residential & Commercial Sales and Leasing, Off Plan and Investments, Property Management, and more. Use the search feature to find a specific person or browse through a department.Not sure what you need? No problem, you can reach out to our Customer Service team via +971 4 302 5800 for support.</p>
            </div>
          </div>
        </div>
        <ContactUsForm setShowContactForm={setShowContactForm} showContactForm={showContactForm} />
        <Footer />
      </div>
    );
  };
  export default AboutUs;