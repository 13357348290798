import React from 'react';

const secPageHeader = ({ pageTitle, pageDesc, imgURL }) => {
  return (
    <div className='secondary-page-header'>
      <div className='bg-image' style={{backgroundImage:`url(${imgURL})`}}>
        {/* <img src='/image-2.png' width='100%' height='100%' /> */}
      </div>
      <div className='overlay-text'>
        <div className='page-title'>
          <div>{pageTitle}</div>
        </div>
        <div className='page-path'>
          <div className='w-70 m-auto'>{pageDesc}</div>
        </div>
      </div>
    </div>
  );
};

export default secPageHeader;