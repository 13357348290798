import React, { useState, useEffect, useRef } from 'react';

const Counter = ({ targetCount, duration, delay }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasStarted(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (hasStarted) {
      let start = 0;
      const increment = Math.ceil(targetCount / (duration / 16));

      const interval = setInterval(() => {
        start += increment;
        if (start >= targetCount) {
          setCount(targetCount);
          clearInterval(interval);
        } else {
          setCount(start);
        }
      }, delay);

      return () => clearInterval(interval);
    }
  }, [hasStarted, targetCount, duration]);

  return (
    <span className='xl-font primary-text' ref={counterRef}>{count}+</span>
  );
};

export default Counter;