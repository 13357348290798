import React, { useState, useEffect, useRef } from 'react';
import PropertiesByCountry from '../components/PropertiesByCountry';
import Counter from '../components/Counter';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { RiDoubleQuotesL } from "react-icons/ri";
import ContactUsForm from '../components/ContactUsForm';
import { Link } from 'react-router-dom';

function Home() {

  const [featureClass, setFeatureClass] = useState('projects-items');

  function showFeatureInfo(targetFeature) {
    setFeatureClass(targetFeature);
  }

  const [showContactForm, setShowContactForm] = useState(false);

  function openContactUs() {
    setShowContactForm(true);
  }

  const [transformY, setTransformY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const bannerTop = document.getElementById('homepage-video-section').offsetTop;
      const scrollPosition = window.scrollY;
      
      if (bannerTop <= 1500) {
        setTransformY(-scrollPosition * 0.2);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [visibleDivs, setVisibleDivs] = useState([]);
  const divRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibleDivs = visibleDivs.slice();

      divRefs.current.forEach((ref) => {
        let id = ref.id;
        const divTop = ref.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        // Check if the element is within the viewport
        if (divTop < windowHeight - 100 && !updatedVisibleDivs.includes(id)) {
          updatedVisibleDivs.push(id);
        }
      });

      setVisibleDivs(updatedVisibleDivs);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [visibleDivs]);

  return (
    <div>
      <div className='header-section'>
        <NavBar />
        {/* Header banner section */}
        <div id="homepage-video-section" className='homepage-video-section' style={{ transform: `translateY(${transformY}px)` }}>
          {/* branding section */}
          {/* <div className='title-section'>
            <div className='title-banner py-4'>
              <h1>Discover Your Dream</h1>
              <h1>Home Around The</h1>
              <h1>World With Us</h1>
            </div>
            <div className='title-caption py-2'>
              <p>Unlock a world of possibilities as we redefine luxury living across 10 countries. <br/> Your dream home is just a click away!</p>
            </div>
            <button className='btn btn-primary py-3 px-5 my-3'>Discover More</button>
            <div className='py-4 d-flex justify-content-start align-items-center text-center'>
              <div className='pe-3'>
                <div className='p-2 md-font fw-bold'>300+</div>
                <h6>Happy Clients</h6>
              </div>
              <div className='px-3'>
                <div className='p-2 md-font fw-bold'>900+</div>
                <h6>Amazing Projects</h6>
              </div>
              <div className='px-3'>
                <div className='p-2 md-font fw-bold'>20+</div>
                <h6>Award Winnings</h6>
              </div>
            </div>
          </div> */}
          {/* marquee section */}
          {/* <div className='marquee-section w-50'>
            <div className='title-marquee d-flex justify-content-end overflow-hidden me-4'>
              <div className="slider px-4">
                <div className="slide-track">
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='1'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='2'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='3'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='4'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='5'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='6'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='7'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='8'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='1'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='2'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='3'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='4'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='5'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='6'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='7'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='8'></img>
                  </div>
                </div>
              </div>
              <div className="slider slider-2">
                <div className="slide-track slider-2-track">
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='1'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='2'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='3'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='4'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='5'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='6'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='7'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='8'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='1'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='2'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='3'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='4'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='5'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='6'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/image-2.png' alt='7'></img>
                  </div>
                  <div className="slide-image">
                    <img width="150px" height="200px" src='/Proj-1-3.webp' alt='8'></img>
                  </div>
                </div>
              </div>
            </div>
            <div className='marquee-branding d-inline-flex float-end align-items-center mb-4'>
              <div className='experience-branding ps-4 p-3 fw-bold'>
                <span className='md-font'>25+</span> Years<br/> Operated
              </div>
              <div className='experience-desc p-3'>
                <p className='m-0'>As a trusted general project that has been operating <br /> for 25 years, our commitment is always to <br /> prioritize our client satisfaction</p>
              </div>
            </div>
          </div> */}
          <div className='home-video-div w-100'>
            <video className='home-page-video' autoplay="autoplay" loop="loop" muted="muted" width="640" height="480" playsinline="">
              <source src="/home-page-2.mp4" type="video/mp4" />
            </video>
          </div>  
          <div className='title-banner'>
            <div className='home-page-header mb-4'>
              <h1 className='home-page-title'>Capital FinServ International</h1>
            </div>
            <div className='home-page-desc'>
              <p className='home-page-sec-title'>The Trusted International Property Consultants & Offshore Investments</p>
            </div>
          </div>
        </div>
        {/* Who we are section */}
      </div>
      <div className='main-content-div'>
        <div className='who-we-are-banner'>
          <div className='container who-we-are-content mt-5'>
            <h2 className='text-center py-3 fw-bold primary-text'>WHO WE ARE?</h2>
            <h2 className='text-center pb-3 fw-bold'>We've found luxury homes for clients for a decade.</h2>
            <div className='who-we-are d-flex justify-content-around align-items-center'>
              <div className='p-3'>
                <Counter targetCount={10} duration={4000} delay={100} />
                {/* <div className='xl-font primary-text'>10+</div> */}
                <span>Countries</span>
              </div>
              <div className='p-3'>
                <Counter targetCount={20} duration={4000} delay={100} />
                {/* <div className='xl-font primary-text'>20+</div> */}
                <span>Years Of Experience</span>
              </div>
              <div className='p-3'>
                <Counter targetCount={500} duration={4000} delay={0} />
                {/* <div className='xl-font primary-text'>{count}+</div> */}
                <span>Properties Listed</span>
              </div>
              <div className='p-3'>
                <Counter targetCount={15} duration={4000} delay={100} />
                {/* <div className='xl-font primary-text'>15+</div> */}
                <span>Awards</span>
              </div>
            </div>
          </div>

          <div ref={(el) => divRefs.current[0] = el} id="floating-div-1" className={`container who-we-are-card position-relative mt-4 floating-div ${visibleDivs.includes('floating-div-1') ? 'visible' : ''}`}>
            <div className='d-flex who-we-are-card-flex justify-content-start align-items-center py-4'>
              <div className='about-us-img position-absolute'>
                <img className='' src='/proj-1-7.jpg'></img>
              </div>
              <div className='about-us-col-img w-100'>
                <img className='' src='/proj-1-7.jpg'></img>
              </div>
              <div className='about-us-content text-start px-5'>
                <div className='py-3'>
                  <h4 className='mb-4'>Your Trusted Partner in Dubai Real Estate</h4>
                  <p>With over 20 years of experience, Capital FinServ International specializes in premium property investments in Dubai. We offer exclusive access to top real estate projects with high returns on investment. Operating in 13+ countries, we bring global expertise to meet your sophisticated needs. Enjoy a free Golden Visa with select investments as we guide you through Dubai's dynamic property market.</p>
                  {/* <button className='btn-white py-3 px-5 mt-2 mb-4'>See More</button> */}
                  <span className='see-more-link'>
                    <Link className=' btn-white py-3 px-5 mt-2 mb-4 text-decoration-none' to="/about-us">See More</Link>
                  </span>
                </div>
              </div>
              {/* <div className='who-we-are-img'> */}
              {/* <div className='about-us-img position-absolute'>
                <img className='' src='/proj-1-7.jpg'></img>
              </div> */}
            </div>
          </div>
        </div>  

        {/* Property Countries List */}
        <div ref={(el) => divRefs.current[1] = el} id="floating-div-2" className={`floating-div ${visibleDivs.includes('floating-div-2') ? 'visible' : ''}`}>
          <PropertiesByCountry />
        </div>

        {/* our best projects */}
        <div ref={(el) => divRefs.current[2] = el} id="floating-div-3" className={`projects-section container my-5 py-5 floating-div ${visibleDivs.includes('floating-div-3') ? 'visible' : ''}`}>
          <div className="text-center ps-4 fw-bold">OUR BEST PROJECTS</div>
          <h2 className="text-center primary-text mb-5 ps-4">Take a glance at our best services.</h2>
          <div id="projects-container-accordion" className={featureClass}>
            <div className="projects-images">
              <div className="projects-image projects-items-image">
                <img src="/property-images/danube.jpg" data-src="/property-images/danube.jpg" data-srcset="/property-images/danube.jpg" alt="Free inventory tracker - Zoho Inventory" width="90%" />
              </div>
              <div className="projects-image projects-uae-image">
              <img src="/property-images/emaar.jpg" data-src="/property-images/emaar.jpg" data-srcset="/property-images/emaar.jpg" alt="Free inventory tracker - Zoho Inventory" width="90%" />
              </div>
              {/* <div className="projects-image projects-istanbul-image">
              <img src="/countries/uk.jpeg" data-src="/image.png" data-srcset="/image.png" alt="Free inventory tracker - Zoho Inventory" width="90%" />
              </div>
              <div className="projects-image projects-saudi-image">
                <img src="/countries/dubai.jpeg" data-src="/image.png" data-srcset="/image.png" alt="Free inventory tracker - Zoho Inventory" width="90%" />
              </div> */}
            </div>

            <div className="projects-list">
              <div className="projects-content">
                <button type="button" className="btn projects-title" onClick={() => showFeatureInfo('projects-items')}>
                  <span>Danube</span>
                  <span className="arrow-down"></span>
                </button>
                <div className="projects-desc projects-items-desc">
                  <div className="projects-image-small projects-items-image">
                    <div>
                      <img className='d-none' src="/property-images/danube.jpg" data-src="/property-images/danube.jpg" data-srcset="/property-images/danube.jpg" alt="Free inventory tracker - Zoho Inventory" width="90%" />
                    </div>
                  </div>
                  <div className="projects-subtitle"><strong>Danube</strong> is our most prestigious project, renowned for its exceptional quality, innovative designs, and commitment to customer satisfaction. As a leading real estate developer, we take pride in delivering projects that set new standards in the industry. Danube is a testament to our dedication to excellence and our ability to create exceptional living spaces.</div>
                  {/* <button className='btn btn-link py-2 px-0'>Learn More</button> */}
                </div>
              </div>
              <div className="projects-content">
                <button type="button" className="btn projects-title" onClick={() => showFeatureInfo('projects-uae')}>
                  <span>Emaar</span>
                  <span className="arrow-down"></span>
                </button>
                <div className="projects-desc projects-uae-desc">
                  <div className="projects-image-small projects-uae-image">
                    <div>
                      <img className='d-none' src="/property-images/emaar.jpg" data-src="/property-images/emaar.jpg" data-srcset="/property-images/emaar.jpg" alt="Free inventory tracker - Zoho Inventory" width="90%" />
                    </div>
                  </div>
                  <div className="projects-subtitle"><strong>Emaar</strong> is a renowned real estate developer that has consistently delivered exceptional projects. With a focus on luxury, innovation, and customer satisfaction, Emaar has established itself as a leader in the industry. Their projects showcase cutting-edge designs, premium amenities, and prime locations, making them highly sought-after by discerning buyers.</div>
                  {/* <button className='btn btn-link py-2 px-0'>Learn More</button> */}
                </div>
              </div>
              {/* <div className="projects-content">
                <button type="button" className="btn projects-title" onClick={() => showFeatureInfo('projects-istanbul')}>
                  <span>Project Istanbul</span>
                  <span className="arrow-down"></span>
                </button>
                <div className="projects-desc projects-istanbul-desc">
                  <div className="projects-image-small projects-istabul-image">
                    <div>
                      <img className='d-none' src="/image.png" data-src="/image.png" data-srcset="/image.png" alt="Free inventory tracker - Zoho Inventory" width="90%" />
                    </div>
                  </div>
                  <div className="projects-subtitle">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem culpa quam, fugit incidunt tenetur ullam.</div>
                  <button className='btn btn-link py-2 px-0'>Learn More</button>
                </div>
              </div>
              <div className="projects-content">
                <button type="button" className="btn projects-title" onClick={() => showFeatureInfo('projects-saudi')}>
                  <span>Project Saudi Arabia</span>
                  <span className="arrow-down"></span>
                </button>
                <div className="projects-desc projects-saudi-desc">
                  <div className="projects-image-small projects-saudi-image">
                    <div>
                      <img className='d-none' src="/image.png" data-src="/image.png" data-srcset="/image.png" alt="Free inventory tracker - Zoho Inventory" width="90%" />
                    </div>
                  </div>
                  <div className="projects-subtitle">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quidem culpa quam, fugit incidunt tenetur ullam.</div>
                  <button className='btn btn-link py-2 px-0'>Learn More</button>
                </div>
              </div> */}
            </div>

          </div>
        </div>


        {/* why choose us */}
        <div ref={(el) => divRefs.current[3] = el} id="floating-div-4" className={`container pt-4 section-seperator floating-div ${visibleDivs.includes('floating-div-4') ? 'visible' : ''}`}>
          <div className='d-flex justify-content-evenly align-items-start mt-5 list-property'>
            <div className='list-property-img'>
              <img src='/list-property-img.png' alt='' />
            </div>
            <div className='list-property-desc text-center w-50'>
              <h1 className='primary-text mb-4'>Maximize Your Property’s <br /> Value with CFSI</h1>
              <div className='py-2 px-5 mb-4'>
              Selling your property with CFSI ensures you achieve the maximum value in today's competitive market. With our extensive experience and deep market knowledge, we provide expert guidance at every step of the selling process. From accurate property valuations to strategic marketing and targeted buyer outreach, we position your property to attract the right buyers quickly. Our global network and personalized approach mean your property is seen by a broad audience, while our negotiation expertise ensures you get the best possible return on your investment. Partner with CFSI and let us help you unlock the full potential of your property.
              </div>
              <Link className='btn btn-primary p-3 my-4' to="/sell">List Your Property</Link>
              {/* <button className='btn btn-primary p-3 my-4'>List Your Property</button> */}
            </div>
          </div>
        </div>
        <div ref={(el) => divRefs.current[5] = el}  id="floating-div-6" className={`cx-testimonial d-flex justify-content-center align-items-center section-seperator floating-div ${visibleDivs.includes('floating-div-6') ? 'visible' : ''}`}>
          <div className='container'>
            <div id="carouselExampleIndicators2" className="carousel slide" data-bs-ride="carousel">
              {/* <div className="carousel-indicators country-nav d-flex justify-content-center position-relative">
                <div className='d-flex align-items-center me-3'>
                  <button className="carousel-control-prev carousel-control-prev-nav position-relative" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                </div>
                {coutriesListSplit.map((countrySplit, index) => (
                  <div data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={`countries-split-nav cursor-pointer ${index === 0 ? 'active' : ''}`}>
                    {countrySplit.map(country => (
                      <span className='country-nav-title' key={country.id}>{country.countyName}</span>
                    ))}
                  </div>
                ))}
                <div className='d-flex align-items-center ms-3'>
                  <button className="carousel-control-next carousel-control-prev-nav position-relative" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div> */}
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">
                  <img src='/pngs/male2.png' alt=''></img>
                </button>
                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="1" aria-label="Slide 2">
                  <img src='/pngs/female1.png' alt=''></img>
                </button>
                <button type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="2" aria-label="Slide 3">
                  <img src='/pngs/male2.png' alt=''></img>
                </button>
              </div>
              <div className="carousel-inner">
                  <h3 className='our-wall-love text-center mt-3'>OUR WALL OF LOVE</h3>
                  <div className={`carousel-item active`}>
                    <div className='m-auto testimonail-cards'>
                      <div className='customer-review p-4 text-center mb-5'>
                        <RiDoubleQuotesL className='icon-lg' />
                        <div className='customer-review-desc w-70 m-auto'>
                        Exceptional service from start to finish! They provided valuable insights, were patient with all my questions, and guided me through each step. I felt confident and well-informed throughout my home-buying journey.
                        </div>
                        <div className='mt-3 customer-review-profile'>
                          <span className='text-end'>Fletcher <br/> Dubai</span>
                        </div>
                      </div>
                    </div>  
                  </div>
                  <div className={`carousel-item`}>
                    <div className='m-auto testimonail-cards'>
                      <div className='customer-review p-4 text-center mb-5'>
                        <RiDoubleQuotesL className='icon-lg' />
                        <div className='customer-review-desc w-70 m-auto'>
                        Highly recommend! They went above and beyond to help me find my dream property. Their expertise, dedication, and personal touch made all the difference. I couldn’t have asked for a better experience!
                        </div>
                        <div className='mt-3 customer-review-profile'>
                          <span className='text-end'> Emma Fletcher<br/>Downtown, Dubai</span>
                        </div>
                      </div>
                    </div>  
                  </div>
                  <div className={`carousel-item`}>
                    <div className='m-auto testimonail-cards'>
                      <div className='customer-review p-4 text-center mb-5'>
                        <RiDoubleQuotesL className='icon-lg' />
                        <div className='customer-review-desc w-70 m-auto'>
                        Working with this team was a fantastic experience! They were professional, responsive, and really took the time to understand what I was looking for.
                        </div>
                        <div className='mt-3 customer-review-profile'>
                          <span className='text-end'>Harrington <br/> UK</span>
                        </div>
                      </div>
                    </div>  
                  </div>
                {/* ))} */}
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="prev">
                <span className="carousel-control-slide d-flex align-items-center justify-content-center" aria-hidden="true">
                  <span className='carousel-control-prev-icon'></span>
                </span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2" data-bs-slide="next">
                <span className="carousel-control-slide d-flex align-items-center justify-content-center" aria-hidden="true">
                  <span className='carousel-control-next-icon'></span>
                </span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div ref={(el) => divRefs.current[4] = el} id="floating-div-5" className={`container section-seperator floating-div d-none ${visibleDivs.includes('floating-div-5') ? 'visible' : ''}`}>
          <h2 className='text-center pt-4 primary-text'>Our Collection</h2>
          <h4 className='text-center pt-2 fw-bold'>Best Projects</h4>
          <div className='projects-container'>
            <div className='position-relative border-prim projects-card'>
              <div className='projects-card-bg' style={{backgroundImage: 'url(/image.png)'}}>
              </div>
              <div className='position-absolute w-100 bottom-10'>
                <div className='d-flex align-items-center justify-content-between m-2 p-2 bg-white'>
                  <div className='me-3'>
                    <h6>Treasury tower</h6>
                    <span>Project Preview</span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center carousel-testimonial-next-icon p-3'>
                    <div className='carousel-control-next-icon'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='position-relative border-prim projects-card'>
              <div className='projects-card-bg' style={{backgroundImage: 'url(/image.png)'}}>
              </div>
              <div className='position-absolute w-100 bottom-10'>
                <div className='d-flex align-items-center justify-content-between m-2 p-2 bg-white'>
                  <div className='me-3'>
                    <h6>Treasury tower</h6>
                    <span>Project Preview</span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center carousel-testimonial-next-icon p-3'>
                    <div className='carousel-control-next-icon'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='position-relative border-prim projects-card'>
              <div className='projects-card-bg' style={{backgroundImage: 'url(/image.png)'}}>
              </div>
              <div className='position-absolute w-100 bottom-10'>
                <div className='d-flex align-items-center justify-content-between m-2 p-2 bg-white'>
                  <div className='me-3'>
                    <h6>Treasury tower</h6>
                    <span>Project Preview</span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center carousel-testimonial-next-icon p-3'>
                    <div className='carousel-control-next-icon'></div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='position-relative border-prim projects-card'>
              <div className='projects-card-bg' style={{backgroundImage: 'url(/image.png)'}}>
              </div>
              <div className='position-absolute w-100 bottom-10'>
                <div className='d-flex align-items-center justify-content-center m-2 p-2 bg-white'>
                  <div className='me-3'>
                    <h6>Treasury tower</h6>
                    <span>Project Preview</span>
                  </div>
                  <div className='d-flex justify-content-center align-items-center carousel-testimonial-next-icon p-3'>
                    <div className='carousel-control-next-icon'></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='contact-us-container container d-flex'>
          <div className='contact-us-card w-100 p-5 rounded'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column me-5'>
                <h2 className='feedback-text letter-spacing-1 line-height-50 m-0'>Got feedback, complaints or questions? <br />Speak to a Customer Service specialist and <br/>get answers</h2>
                {/* <p className='text-muted'>Get personalised assistance from our dedicated team of experts.</p> */}
              </div>
              <div className='w-30 d-flex justify-content-center align-items-center'>
                <button onClick={openContactUs} className='btn btn-primary mx-2 px-5 py-3'>Contact Us</button>
              </div>
            </div>
          </div>
          {/* <div className='contact-us-card w-50 m-5'>
            <div className='d-flex flex-column'>
              <h3>Speak to a Customer Service specialist</h3>
              <p className='text-muted'>Got feedback, complaints or questions? Get personalised assistance from our dedicated team of experts.</p>
              <button className='btn btn-secondary mx-2'>Contact Us</button>
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
      <ContactUsForm setShowContactForm={setShowContactForm} showContactForm={showContactForm} />
    </div>  
  );  
}

export default Home;
