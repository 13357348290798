import React, { useState, useEffect }  from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser'
import ContactUsForm from '../components/ContactUsForm';
import SecondaryPageHeader from '../components/SecondaryPageHeader';
import { Carousel } from 'react-bootstrap';import PageSkeleton from '../components/PageSkeleton';
;

function PropertyDetails() {

  const { property_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  

  const getPropertyDetails = async () => {
    try {
      setPageLoading(true);
      const { data = {}} = await axios.get(`https://cfsi-strapi.onrender.com/api/properties/${property_id}`);
      // const { data = {}} = await axios.get(`http://localhost:1337/api/properties/${property_id}`);
      setPropertyDetails(data || {});
      setIsLoading(false);
    } catch (error) {
      console.error('Error making network request', error);
    } finally {
      setPageLoading(false);
    }
  };

  const initialize = () => {
    getPropertyDetails();
    // Add any other initialization logic here
  };

  // useEffect hook to call the initialize function on component mount
  useEffect(() => {
    initialize();
  }, []);

  // function currencyFormat(num = 0, currencyCode) {
  //   return currencyCode + ' ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  function showContactUsForm() {
    setShowContactForm(true);
  }


  return (
    <>
      {
        isPageLoading ? <PageSkeleton /> : 
        (<>
        <div className='header-section property-page-header'>
          <Navbar />
          <SecondaryPageHeader pageTitle={propertyDetails.title} pageDesc={propertyDetails.about_property} imgURL={propertyDetails.primary_image.url} />
        </div>
        {/* <ImagePreviewGrid primaryImage={propertyDetails.primary_image} imagesList={propertyDetails.images_list} /> */}
        <div className='main-content-div'>
          <div className='container property-details-container'>
            <div>
              <div>
                <h2 className='mb-0 mt-3 py-4 primary-text text-center'>
                  About {propertyDetails.title}
                </h2>
                <p className='w-80 m-auto project-about-details'>{propertyDetails.description}</p>
              </div>
              <div className='secondary-img-sec'>
                <img className='container property-second-img' src={propertyDetails.primary_image.url} />
              </div>
              {/* <div className='d-flex justify-content-start align-items-center text-muted mb-3'><IoLocationSharp />{propertyDetails.display_address}</div> */}
              {/* <div className='details-card d-grid w-100'>
                <div className='details-card-item d-flex flex-column'>
                  <img src='/bed.png' alt='' className='details-card-item-icons' />
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='primary-text mb-0'>{propertyDetails.bedrooms}</h3>
                    <div>Bedrooms</div>
                  </div>
                </div>     
                <div className='details-card-item d-flex flex-column'>
                  <img src='/bathroom.png' alt='' className='details-card-item-icons' />
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='primary-text mb-0'>{propertyDetails.bathrooms}</h3>
                    <div>Bathrooms</div>
                  </div>
                </div> 
                <div className='details-card-item d-flex flex-column'>
                  <img src='/area.png' alt='' className='details-card-item-icons' />
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h5 className='primary-text mb-0'>{propertyDetails.floor_area_min} sq.ft - {propertyDetails.floor_area_max} sq.ft</h5>
                    <div>Floor Area</div>
                  </div>
                </div>      
              </div> */}
              <div className='feature-section-details'>
                {propertyDetails?.features.map((feature, index) => (
                  <div className={`d-flex m-auto my-5 ${(index%2 === 0) ? '' : 'flex-row-reverse'}`}>
                    <div className={ `w-50 feature-img-slider ${feature.feature_img.length <=1 ? 'hide-arrows' : ''} ${(index%2 === 0) ? 'property-features' : ''}`}>
                      <Carousel>
                        {/* <Carousel.Item>
                          <img className="d-block w-100" height="200px" src={attributes.primary_image?.data?.attributes?.url} alt="First slide" />
                        </Carousel.Item> */}
                        {feature.feature_img?.map(img => (
                          <Carousel.Item className='w-80'>
                            <img className="d-block w-100" src={img?.url} />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <img className='w-80' src={feature.feature_img.url}/>
                    </div>
                    <div className={`features-section-content w-50 ${(index%2 === 0) ? '' : 'property-features'}`}>
                      <div className='d-flex justify-content-center align-item-center flex-column w-80 m-auto h-100'>
                        <h3 className='primary-text'>{feature.name}</h3>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='mt-4 location-iframe'>
                <h3 className='mb-3'>Location</h3>
                {parse(propertyDetails.location_iframe_url || "")}
              </div>
            </div>
            <div>
              <div className='side-bar-features'>
                <div className='features-container-card interested-in-property'>
                  <h5 className='text-center mb-3'>Interested in this Property?</h5>
                  <h6 className='text-center'>For More Information</h6>
                  <hr />
                  <div className='contact-us-btns d-flex mx-5'>
                    <button onClick={showContactUsForm} className='btn btn-sec flex-grow-1 me-1'><FaPhoneAlt size={10} className='me-2' />Contact Us</button>
                    <button className='btn btn-sec flex-grow-1 ms-1'><FaWhatsapp size={15} className='me-1' /> Whatsapp</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
    
          {/* footer */}
          <ContactUsForm setShowContactForm={setShowContactForm} showContactForm={showContactForm} />
          <Footer />
        </div>
        </>)
      }
    </>
  )
}    

export default PropertyDetails;