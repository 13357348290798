import React, { useState }  from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import ContactUsForm from '../components/ContactUsForm';
import SecondaryPageHeader from '../components/SecondaryPageHeader';

export const ContactUs = () => {

  const [showContactForm, setShowContactForm] = useState(false);

  function openContactUs() {
    setShowContactForm(true);
  }

  return (
    <div>
      <div className='header-section'>
        <Navbar />
        <SecondaryPageHeader pageTitle="Contact Us" pageDesc="Home / ContactUs" imgURL="/secondary-page-bg.png" />
      </div>
      <div className='container mb-5'>
        <div className='d-flex mx-5 mt-5'>
          <div className='w-70'>
            <h1 className='primary-text letter-spacing-15 mb-4'>We are here to help you!</h1>
            <p className='md-font mb-4 letter-spacing-1 text-muted'>Looking for expert advice or further support?</p>
            <p className='md-font letter-spacing-1 text-muted'>From Property Consultants to Marketing experts, Support and more, our team of real estate specialists is here to help. If you're not sure who to speak to, our Customer Service team can guide you.</p>
          </div>
        </div>  
        <div className='d-flex mt-5'>
          <div className='contact-us-card w-100 p-5 rounded'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex flex-column me-5'>
                <h2 className='feedback-text letter-spacing-1 line-height-50 m-0'>Got feedback, complaints or questions? <br />Speak to a Customer Service specialist and <br/>get answers</h2>
                {/* <p className='text-muted'>Get personalised assistance from our dedicated team of experts.</p> */}
              </div>
              <div className='w-30 d-flex justify-content-center align-items-center'>
                <button onClick={openContactUs} className='btn btn-primary mx-2 px-5 py-3'>Contact Us</button>
              </div>
            </div>
          </div>
          {/* <div className='contact-us-card w-50 m-5'>
            <div className='d-flex flex-column'>
              <h3>Speak to a Customer Service specialist</h3>
              <p className='text-muted'>Got feedback, complaints or questions? Get personalised assistance from our dedicated team of experts.</p>
              <button className='btn btn-secondary mx-2'>Contact Us</button>
            </div>
          </div> */}
        </div>
      </div>
      <ContactUsForm setShowContactForm={setShowContactForm} showContactForm={showContactForm} />
      <Footer />
    </div>
  );
};
export default ContactUs;