import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import {coutriesList} from '../utils/constants';

function CenterMode() {
  // const [slideIndex, setSlideIndex] = useState(0);
  // const [updateCount, setUpdateCount] = useState(0);
  let sliderRef = useRef(null);
  // let sliderRefNav = useRef(null);
  // useEffect(() => {
  //   // Whenever variableA changes, update variableB
  //   sliderRefNav.slickGoTo(slideIndex) // Example: set variableB to twice the value of variableA
  // }, [slideIndex]);

  const [scrrenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [coutryCardsToShow, setcoutryCardsToShow] = useState(0);

  useEffect(() => {
    const handleScreenResize = () => {
      const newSize = { width: window.innerWidth, height: window.innerHeight };
      setScreenSize(newSize);
      setcoutryCardsToShow(computecoutryCardsToShow(newSize));
    };

    // Initial computation
    setcoutryCardsToShow(computecoutryCardsToShow(size));

    window.addEventListener('resize', handleScreenResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleScreenResize);
  }, [scrrenSize]);

  const computecoutryCardsToShow = (size) => {
    // Example computation: Compute value based on the width of the screen
    return Number(size.width) < 500 ? 1 : 3; // You can change this logic based on your requirements
  };

  const settings = {
    // customPaging: function(i) {
    //   return (
    //     <div className="d-flex">
    //       <span className='country-nav-title'>{`${coutriesList[i].countyName + i}`}</span>
    //     </div>
    //   );
    // },
    // dots: true,
    // dotsClass: "slick-dots slick-thumb",
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: coutryCardsToShow,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    // afterChange: () => setUpdateCount(updateCount + 1),
    // beforeChange: (current, next) => setSlideIndex(next)
  };

  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [coutryNavToShow, setcoutryNavToShow] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const newSize = { width: window.innerWidth, height: window.innerHeight };
      setSize(newSize);
      setcoutryNavToShow(computecoutryNavToShow(newSize));
    };

    // Initial computation
    setcoutryNavToShow(computecoutryNavToShow(size));

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [size]);

  const computecoutryNavToShow = (size) => {
    // Example computation: Compute value based on the width of the screen
    return Number(size.width) < 768 ? 3 : 5; // You can change this logic based on your requirements
  };

  const settingsNav = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: coutryNavToShow,
    cssEase: "linear",
    // afterChange: () => {
    //   setUpdateCount(updateCount + 1);
    // },
    // beforeChange: (current, next) => {
    //   if (current < next && current <= 0) {
    //     next = current - 1; 
    //   }
    //   if (current >= 8) {
    //     next
    //   }
    //   setSlideIndex(next);
    //   sliderRef.slickGoTo(next);
    // }
  };
  return (
    <div className='container mt-5'>
      <h2 className='text-center pt-4 primary-text'>ACROSS 10+ COUNTRIES</h2>
      <h5 className='text-center pt-2 fw-bold'>Find Property By Country</h5>
      <div className='mt-4'>
        <div className="slider-container">
          {/* <div className="w-80 mx-auto mb-2">
            <Slider {...settingsNav} ref={slider => { sliderRefNav = slider;}} className="nav-slider-class">
              {coutriesList.map((country, index) => (
                <span>
                  <span className='country-nav-title text-center' onClick={e => sliderRef.slickGoTo(index)} key={country.id}>{country.countyName}</span>
                </span>
              ))}
            </Slider>
          </div> */}
          <Slider ref={slider => { sliderRef = slider;}} {...settings} className="country-card-slider">
            {coutriesList.map(country => (
              <div className='d-flex justify-content-evenly m-2'>
                  <Link to={`/buy?countryCode=${country.countryCode}`} key={country.id} className={`country-card-container ${country.class}`} style={{backgroundImage: `url(${country.imageUrl})`}}>
                    {/* <img width="100%" height="100%" src={country.imageUrl} alt=''> */}
                    {/* </img> */}
                    <div className='country-card-title-bg position-absolute bottom-0 end-0 w-100 p-4'>
                    </div>
                    <div className='country-card-title position-absolute bottom-0 end-0 w-100 text-center pb-2'>
                      {country.countyName}
                    </div>
                  </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default CenterMode;
